import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { StaticImage, getImage } from "gatsby-plugin-image"
import { PostItemCard } from "./PostItemCard"
import {
  bgImgClass,
  containerClass,
  blogLinkClass
} from "./RecommendedPosts.module.scss"
import { Container } from "react-bootstrap"

const RecommendedPosts = ({ ...props }) => {
  const data = useStaticQuery(graphql`
    {
      allWpPost(
        filter: {
          tags: { nodes: { elemMatch: { slug: { eq: "recommended" } } } }
        }
        limit: 4
      ) {
        nodes {
          uri
          date(formatString: "DD MMM YYYY", locale: "en-GB")
          title
          id
          slug
          postsLayouts {
            postObject {
              ... on WpPost_Postslayouts_PostObject_PostContent {
                blurb
                fieldGroupName
                mainImage {
                  altText
                  sourceUrl
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP, AVIF]
                        width: 420
                        placeholder: NONE
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const filteredData = data.allWpPost.nodes
    .filter(item => item.uri !== props.uri)
    .slice(0, 3)

  return (
    <div className="position-relative h-100 p-0">
      <StaticImage
        className={bgImgClass}
        src="../../../images/bkgd-dots-optimized.png"
        alt=""
      />
      <Container className={containerClass} fluid="md">
        <div>
          <div className="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-start align-items-center flex-wrap mb-5">
            <h2
              className="m-0 p-0 me-4"
              dangerouslySetInnerHTML={{ __html: props.sectionHeading }}
            />
            <Link className={blogLinkClass} to="/blog">
              Read all posts
            </Link>
          </div>
          <Row>
            {filteredData.map(post => {
              const {
                title,
                date,
                id,
                slug,
                postsLayouts: { postObject }
              } = post

              const { mainImage } = postObject[0]

              const mainImg = getImage(mainImage?.localFile)

              return (
                <Col
                  className="d-flex justify-content-center justify-content-lg-start mb-4"
                  sm={12}
                  lg={4}
                  key={id}>
                  <PostItemCard
                    slug={slug}
                    image={mainImg}
                    alt={mainImage?.altText}
                    date={date}
                    title={title}
                  />
                </Col>
              )
            })}
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default RecommendedPosts
