import React from "react"
import { Link } from "gatsby"
import Card from "react-bootstrap/Card"
import { GatsbyImage } from "gatsby-plugin-image"
import { createLocalLink } from "../../../utils"
import {
  postCardClass,
  mainImageClass,
  titleLinkClass
} from "./PostItemCard.module.scss"

export const PostItemCard = ({ slug, image, alt, date, title }) => {
  return (
    <Card className={postCardClass}>
      <Link className={titleLinkClass} to={createLocalLink(`/blog/${slug}`)}>
        {image && (
          <GatsbyImage className={mainImageClass} image={image} alt={alt} loading="lazy" />
        )}
      </Link>
      <Card.Body className={`d-flex flex-column`}>
        <span
          className="mb-2 small text-violet font-weight-bold"
          dangerouslySetInnerHTML={{ __html: date }}
        />
        <h3>
          <Link
            className={titleLinkClass}
            to={createLocalLink(`/blog/${slug}`)}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </h3>
      </Card.Body>
    </Card>
  )
}
