import React from "react"
import { graphql } from "gatsby"
import { Article } from "../Article"
import RecommendedPosts from "../UI/Post/RecommendedPosts"
import {
  sectionClass,
} from "./PostContent.module.scss"

export const fragment = graphql`
  fragment postContentFragment on WpPost_Postslayouts_PostObject_PostContent {
    postTitle
    blurb
    mainImage {
      altText
      sourceUrl
      localFile {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            width: 1032
            placeholder: BLURRED
          )
        }
      }
    }
    postBody {
      ... on WpPost_Postslayouts_PostObject_PostContent_PostBody_SectionHeadingContent {
        sectionHeading
        headingType
      }
      ... on WpPost_Postslayouts_PostObject_PostContent_PostBody_TextContent {
        text
      }
      ... on WpPost_Postslayouts_PostObject_PostContent_PostBody_ImageContent {
        image {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                width: 680
                placeholder: BLURRED
              )
            }
          }
        }
      }
      ... on WpPost_Postslayouts_PostObject_PostContent_PostBody_DoubleImageBlockContent {
        doubleImageBlock {
          leftImage {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  width: 420
                  placeholder: BLURRED
                )
              }
            }
          }
          rightImage {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  width: 420
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
      ... on WpPost_Postslayouts_PostObject_PostContent_PostBody_QuoteContent {
        quote
      }
      ... on WpPost_Postslayouts_PostObject_PostContent_PostBody_VideoContent {
        video
      }
    }
  }
`

export const PostContent = ({
  location,
  history,
  pageContext,
  date,
  author,
  blurb,
  postTitle,
  headingType,
  mainImage,
  postBody,
  uri,
  jsonLDSchema
    }) => {
  return (
    <section className={sectionClass}>
      <Article
        jsonLDSchema={jsonLDSchema}
        location={location}
        history={history}
        pageContext={pageContext}
        date={date}
        author={author}
        blurb={blurb}
        postTitle={postTitle}
        headingType={headingType}
        mainImage={mainImage}
        postBody={postBody}
        uri={uri}
      />
      <RecommendedPosts
        sectionHeading={"More on our blog"}
        buttonLink={"/blog"}
        buttonText={"View all news"}
        uri={uri}
      />
    </section>
  )
}
