import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"

import { PostContent } from "../components/AcfPostBlocks"

export const postQuery = graphql`
  query GET_POST($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      content
      uri
      date(formatString: "DD MMM YYYY", locale: "en-GB")
      author {
        node {
          name
        }
      }
      postsLayouts {
        postObject {
          __typename
          ...postContentFragment
        }
      }
      jsonLDSchema {
        medicalSchema {
          mainContentOfPage
          lastReview
          nextReview
          enabled
          authors {
            biographyLink
            fieldGroupName
            name
          }
          reviewers {
            biographyLink
            fieldGroupName
            name
          }
        }
      }
      seo {
        title
        metaDesc
        opengraphImage {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
      }
    }
  }
`

const Post = ({ location, history, data, pageContext }) => {
  const {
    title,
    uri,
    date,
    author: {
      node: { name }
    },
    postsLayouts: { postObject },
    jsonLDSchema,
    seo
  } = data.wpPost


  return (
    <Layout seo={seo} location={location}>
      {postObject.length > 0 &&
        postObject.map((block, i) => {
          switch (block.__typename) {
            case "WpPost_Postslayouts_PostObject_PostContent":
              return (
                <PostContent
                  jsonLDSchema={jsonLDSchema}
                  location={location}
                  history={history}
                  pageContext={pageContext}
                  date={date}
                  author={name}
                  key={`PostContent${i}`}
                  headingType='h1'
                  uri={uri}
                  {...block}
                />
              )
            default:
              console.log("This is the End!", block.__typename)
              return "" // this should not happen
          }
        })}
    </Layout>
  )
}

export default Post
